import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme }) => theme.media.desktop} {
    width: 30%;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 10px;
  }
`;
const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const H3 = styled.div`
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xxxm};
  margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
  margin-bottom: 10px;
  font-weight: 600;
`;

const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};
  margin-right: ${({ isLeft }) => (isLeft ? "60px" : "5px")};
`;

const Img = styled.img`
  width: ${({ isLeft }) => (isLeft ? "200px" : "25px")};
  height: ${({ isLeft }) => (isLeft ? "280px" : "20px")};

  margin-top: 0;
`;
const LinksHead = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 3px 0;
`;

const Journal = ({ category }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsJournal(sort: { fields: sort }) {
        nodes {
          id
          categoryfr
          title
          link
          link2
          turnofflink
        }
      }
    }
  `);

  return (
    <StyleWrapper>
      <H3 isBlue>{category}</H3>

      {data.allDatoCmsJournal.nodes
        .filter((Jour) => Jour.categoryfr === category)
        .map((Jour) => (
          <StyledDiv key={Jour.id}>
            <LinksHead>
              <StyledP>{Jour.title}</StyledP>
              {!Jour.turnofflink ? (
                <a href={Jour.link}>
                  <Img
                    src="https://old.wydawnictwokatechetyczne.pl/assets/iccsv.png"
                    alt="icon_csv"
                  />
                </a>
              ):(<StyledP>bd.</StyledP>)}

              {!Jour.turnofflink ? (
                <a href={Jour.link2}>
                  <Img
                    src="https://old.wydawnictwokatechetyczne.pl/assets/icxls.png"
                    alt="icon_xls"
                  />
                </a>
              ):null}
            </LinksHead>
          </StyledDiv>
        ))}
    </StyleWrapper>
  );
};
Journal.propTypes = {
  category: PropTypes.string.isRequired,
};
export default Journal;
