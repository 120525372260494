import React from "react"
import MainTemplate from 'templates/MainTemplate'
import SEO from 'components/SEO/seo'
import Journal from 'components/FreeMaterials/Journals/Journal';
import {StyledFreeMaterials, StyleWrapper, StyledInner, StyledTitile } from 'components/FreeMaterials/FreeMaterials.styled'



const JournalPage = () => (
<MainTemplate>
<SEO title="Poradniki metodyczne" />
    <StyledFreeMaterials>
        <StyleWrapper>

        <StyledTitile isGreen id="dziennik"><h1><strong>Dziennik Elektroniczny</strong></h1></StyledTitile>
            <StyledInner>
              <Journal category="Szkoła Podstawowa kl.1-3"/>
              <Journal category="Szkoła Podstawowa kl.4-8"/>
              <Journal category="Liceum/Technikum"/>
            </StyledInner>

        </StyleWrapper>
    </StyledFreeMaterials>

</MainTemplate>
)

export default JournalPage
