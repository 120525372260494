
import styled from 'styled-components';

export const StyledFreeMaterials = styled.div`
width: 100%;
height: auto;

display: flex;
justify-content: center;
padding-top: 20px;
background-color: ${({theme}) => theme.body};
font-family: ${({theme}) => theme.font.family.Montserrat};
${({theme}) => theme.media.desktop} {
width: 100%;
min-height:calc(100vh - 390px);
display: -webkit-box;
display: -ms-flexbox;
display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
justify-content: center;
padding-top: 50px;
}`

export const StyleWrapper = styled.div`
width: 100%;
max-width: 1100px;
height: auto;
${({theme}) => theme.media.desktop} {
width: 70%;
min-width:900px;
max-width: 1100px;
height: auto;
}
`

export const StyledInner = styled.div`
display:flex;
flex-direction: column;
overflow: hidden;
padding: 15px;
padding-bottom: 0;
border: 1px solid rgba(0, 0, 0, 0.15);
background-color: rgba(255, 255, 255, 0.7);
margin-bottom: 20px;
${({theme}) => theme.media.desktop} {
display:flex;
flex-direction: row;
flex: 1;
padding: 15px;
padding-bottom: 0;
border: 1px solid rgba(0, 0, 0, 0.15);
background-color: rgba(255, 255, 255, 0.7);
margin-bottom: 20px;
}`


export const StyledTitile = styled.div`
padding: 6px 18px;
background-color: ${({isGreen}) => isGreen ? 'rgba(120,200,40,1);' : 'rgba(0,160,240,1)'};
font-weight: 400;
color: white;
`



